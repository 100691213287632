const getDefaultState = () => {
  return {
    planningFilters: []
  };
};

const state = getDefaultState();

const getters = {
  getPlanningFilters: (state) => state.planningFilters,
};

const mutations = {
  setPlanningFilters (state, payload) {
    let index = state.planningFilters.findIndex((p) => p.path === payload.path);
    if (index > -1) {
      state.planningFilters[index] = payload
    } else {
      state.planningFilters.push(payload)
    }
  },

  resetPlanningFilters (state, payload) {
    state.planningFilters = state.planningFilters.filter((p) => p.path !== payload)
  },

  restorePlanningFilters (state, payload) {
    state.planningFilters = payload
  }
};

export default {
  state,
  getters,
  mutations,
};
