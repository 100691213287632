export default [
  // USER PROFILE
  {
    name: "UserProfile",
    path: "/user-profile",
    component: () => import("@/views/UserProfile"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
      title: "Informações do usuário",
    },
  },

  // APPROVAL FLOW
  {
    name: "ApprovalFlow",
    path: "/approval-flow",
    component: () => import("@/views/ApprovalFlow"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ApprovalFlowLevel",
    path: "/approval-flow-level",
    component: () => import("@/views/ApprovalFlowLevel"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ApprovalFlowTransaction",
    path: "/approval-flow-transaction",
    component: () => import("@/views/ApprovalFlowTransaction"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  // {
  //     name: 'ApprovalFlowCostCenter',
  //     path: '/approval-flow-cost-center',
  //     component: () =>
  //         import('@/views/ApprovalFlowCostCenter'),
  //         meta: {
  //             requireAuth: true,
  //             redirectHomeIfLogged: false
  //         }
  // },
  {
    name: "PlanningGroupVersion",
    path: "/planning-group-version",
    component: () => import("@/views/PlanningGroupVersion"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "PlanningGroup",
    path: "/planning-group",
    component: () => import("@/views/PlanningGroup"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ApprovalFlowPlanningGroup",
    path: "/approval-flow-planning-group",
    component: () => import("@/views/ApprovalFlowPlanningGroup"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "EventStatusPlanningGroup",
    path: "/event-status-planning-group",
    component: () => import("@/views/EventStatusPlanningGroup"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },

  // ACCOUNT
  {
    name: "ChartAccountGroup",
    path: "/chart-account-group",
    component: () => import("@/views/ChartAccountGroup"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "AccountGroup",
    path: "/account-group",
    component: () => import("@/views/AccountGroup"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "AccountGroupCalc",
    path: "/account-group-calc",
    component: () => import("@/views/AccountGroupCalc"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "AccountGroupVersion",
    path: "/account-group-version",
    component: () => import("@/views/AccountGroupVersion"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "AccountGroupReference",
    path: "/account-group-reference",
    component: () => import("@/views/AccountGroupReference"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "AccountGroupSystemId",
    path: "/account-group-system-id",
    component: () => import("@/views/AccountGroupSystemId"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "ChartAccountGroupVersion",
    path: "/chart-account-group-version",
    component: () => import("@/views/ChartAccountGroupVersion"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "AccountGroupFilterVersion",
    path: "/account-group-filter-version",
    component: () => import("@/views/AccountGroupFilterVersion"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "AccountGroupFilter",
    path: "/account-group-filter",
    component: () => import("@/views/AccountGroupFilter"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },

  // EVENT
  {
    name: "Event",
    path: "/event",
    component: () => import("@/views/Event"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "EventConfig",
    path: "/event-config",
    component: () => import("@/views/EventConfig"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "EventConfigAccountGroup",
    path: "/event-config-account-group",
    component: () => import("@/views/EventConfigAccountGroup"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },

  // PRODUCT
  {
    name: "ProductSalesPlanning",
    path: "/product-sales-planning",
    component: () => import("@/views/ProductSalesPlanning"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },

  // SALES PLANNING
  {
    name: "SalesPlanning",
    path: "/sales-planning",
    component: () => import("@/views/SalesPlanning"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "EmployeeAllocationPlanning",
    path: "/employee-allocation-planning",
    component: () => import("@/views/EmployeeAllocationPlanning"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    props: { idPlanningFunction: 3 },
  },
  {
    name: "EmployeeHoursControl",
    path: "/employee-hours-control",
    component: () => import("@/views/EmployeeHoursControl"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "FinancialEmployeeHoursControl",
    path: "/financial-employee-hours-control",
    component: () => import("@/views/FinancialEmployeeHoursControl"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "EmployeeTotalCost",
    path: "/employee-total-cost",
    component: () => import("@/views/EmployeeTotalCost"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    props: { idPlanningFunction: 21 },
  },
  {
    name: "FinancialEmployeeTotalCost",
    path: "/financial-employee-total-cost",
    component: () => import("@/views/EmployeeTotalCost"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    props: { idPlanningFunction: 22 },
  },
  {
    name: "InvoicingPlanning",
    path: "/invoicing-planning",
    component: () => import("@/views/InvoicingPlanning"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    props: { idPlanningFunction: 14 },
  },
  {
    name: "SalesPlanningCustomer",
    path: "/sales-planning-customer",
    component: () => import("@/views/SalesPlanningCustomer"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    props: { idPlanningFunction: 2 },
  },
  {
    name: "FinancialPerCapitaCost",
    path: "/financial-per-capita-cost",
    component: () => import("@/views/SalesPlanningCustomer"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    props: { idPlanningFunction: 24 },
  },
  {
    name: "FinancialContribution",
    path: "/financial-contribution",
    component: () => import("@/views/InvoicingPlanning"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    props: { idPlanningFunction: 15 },
  },
  {
    name: "FinancialEmployeeAllocationPlanning",
    path: "/financial-employee-allocation-planning",
    component: () => import("@/views/EmployeeAllocationPlanning"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
    props: { idPlanningFunction: 16 },
  },
  {
    name: "ReportGallery",
    path: "/report-gallery",
    component: () => import("@/views/ReportGallery"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "SalesChannelType",
    path: "/sales-channel-type",
    component: () => import("@/views/SalesChannelType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "SalesChannel",
    path: "/sales-channel",
    component: () => import("@/views/SalesChannel"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "SalesForceType",
    path: "/sales-force-type",
    component: () => import("@/views/SalesForceType"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "SalesForce",
    path: "/sales-force",
    component: () => import("@/views/SalesForce"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "DetailedSalesPlanning",
    path: "/detailed-sales-planning",
    component: () => import("@/views/DetailedSalesPlanning"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
  {
    name: "SalesReport",
    path: "/sales-report",
    component: () => import("@/views/SalesReport"),
    meta: {
      requireAuth: true,
      redirectHomeIfLogged: false,
    },
  },
];
