import Vue from "vue";
import Vuetify from "vuetify/lib";

import pt from "./locales/pt";
import en from "./locales/en";

import dotenv from 'dotenv'
dotenv.config()

const cspnonce = process.env.VUE_APP_CSPNONCE

Vue.use(Vuetify, { cspNonce: cspnonce});

export default new Vuetify({
  theme: {
    options: { customProperties: true, cspNonce: cspnonce},
    themes: {
      light: {},
      dark: false,
    },
  },

  lang: {
    locales: { pt, en },
    current: "pt",
  },
});
